
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { timeOperation } from './common.js';
import { ActionButton } from './components.js';
import { Messages } from './context.js';
import ContentPlayer from './content/ContentPlayer.js';
import SimpleHeaderBar from './SimpleHeaderBar.js';

const PurpleWord = ({ children, variant }) => {
  return (
    <Typography variant={variant || 'h4'} color="purple.main" component="span" sx={{ fontWeight: 500 }}>
      {children}
    </Typography>
  );
};

const HomeView = () => {
  const navigate = useNavigate();
  const { setMessage } = useContext(Messages);

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  const isMobile = useMediaQuery('(max-width: 1240px)');

  const handleAccessRequest = async () => {
    setSubmitting(true);
    const [loadTime, response] = await timeOperation(() => fetch('/api/feedback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, phone, type: 'JoinRequest' }),
    }));
    setSubmitting(false);

    if (response.ok) {
      mixpanel.track('Submitted feedback', { loadTime });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'formSubmission',
        formType: 'NewUserJoinRequest',
      });

      setSubmitted(true);
      setMessage({
        children: 'Submitted! Thank you for your interest in VoiceTopics.',
        severity: 'success'
      });
    } else {
      const errorMessage = await response.text();
      mixpanel.track('Error submitting feedback', { loadTime, errorMessage });
      setMessage({ children: errorMessage, severity: 'error' });
    }
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Stack direction="column" sx={{ width: '100%', alignItems: 'center' }}>

        <SimpleHeaderBar actionButton={
            <ActionButton color="white"
                size={30}
                sx={{
                  border: '1px solid black',
                  boxShadow: 'none',
                  fontWeight: 500,
                }}
                onClick={() => navigate('/login')}>
              Login
            </ActionButton>
        } />

        <Stack direction={isMobile ? "column" : "row"}
            sx={{
              px: 4,
              py: isMobile ? 4 : 8,
              maxWidth: 1440,
              justifyContent: 'center',
              margin: '0 !important'
            }}
            spacing={8}>

          <Stack direction="column" spacing={4} sx={{ flexBasis: 0, flexGrow: 1 }}>
            <Typography variant="h4">
              Your single tool for transforming articles into&nbsp;
              <PurpleWord variant="h4">audio</PurpleWord>
            </Typography>
            <Typography variant="h5">
              Create, edit, share, measure, and monetize audio content exactly as you&nbsp;
              <Typography variant="h5" sx={{ textDecoration: 'line-through' }} component="span">
                envision
              </Typography>
              &nbsp;hear it
            </Typography>
            <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
              <Typography variant="h6">
                Submit your information to get access (we&apos;ll email & call you)
              </Typography>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <TextField
                    size="small"
                    label="Name"
                    sx={{ flexGrow: 1 }}
                    onChange={(event) => setName(event.target.value)} />
                <TextField
                    size="small"
                    label="Email"
                    sx={{ flexGrow: 1 }}
                    onChange={(event) => setEmail(event.target.value)} />
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <TextField
                    size="small"
                    label="Phone number"
                    sx={{ flexGrow: 1, flexBasis: 0 }}
                    onChange={(event) => setPhone(event.target.value)} />
                  <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                    <ActionButton
                        onClick={handleAccessRequest}
                        sx={{ px: 8, minWidth: '100%', whiteSpace: 'nowrap' }}
                        disabled={!name || !email || submitted || submitting}>
                      {submitting && <CircularProgress size={32} />}
                      {submitted && 'Thanks!'}
                      {!submitting && !submitted && 'Let\'s roll!'}
                    </ActionButton>
                  </Box>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={4} sx={{ flexBasis: 0, flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
            <video controls width={isMobile ? 300 : 600} style={{ borderRadius: 8 }}>
              <source src="demo.mp4" type="video/mp4" />
            </video>
          </Stack>
        </Stack>

        <Box sx={{ background: 'gray.main', width: '100%', justifyContent: 'center', display: 'flex' }}>
          <Stack direction={isMobile ? "column" : "row"}
              sx={{ px: 4, py: isMobile ? 4 : 8, alignItems: 'center', maxWidth: 1440 }}
              spacing={16}>
            <Stack direction="column" spacing={4} sx={{ flexBasis: 0, flexGrow: 1 }}>
              <Typography variant="h4">
                For your&nbsp;
                <PurpleWord>readers</PurpleWord>
                ...
              </Typography>
              <Typography variant="h6">
                make it easy to take your content on the go and consume in ways they never have
                before… driving in the car, doing the dishes, going on a run.
              </Typography>
              <Typography variant="h4">
                For your&nbsp;
                <PurpleWord>business</PurpleWord>
                ...
              </Typography>
              <Typography variant="h6">
                expand your audience and attract new readers that never had the time to read your
                content or simply prefer to listen.
              </Typography>
              <Typography variant="h4">
                For your&nbsp;
                <PurpleWord>wallet</PurpleWord>
                ...
              </Typography>
              <Typography variant="h6">
                place ads in your newly created audio content – expanding your revenue and
                advertising options.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={4} sx={{
                flexBasis: 0,
                flexGrow: 1,
                margin: isMobile && '0 !important',
                py: 4
            }}>
              <Typography variant="h5">
                Don&apos;t believe us?&nbsp;
                <PurpleWord variant="h5">Listen for yourself.</PurpleWord>
              </Typography>
              <ContentPlayer
                  publicMode={true}
                  gridMode={true}
                  noLink={true}
                  dateMode="publishDate"
                  content={{
                    currentVersionData: {
                      status: 'RECORDED',
                      metadata: {
                        title: 'Welcome to VoiceTopics',
                        author: 'VoiceTopics',
                      },
                      audio: {
                        duration: 83.736,
                        uri: 'https://s3.us-east-1.amazonaws.com/voice-topics-audio-bucket-dev/audio-2bd7646bebc9.mp3'
                      }
                    }
                  }} />
            </Stack>
          </Stack>
        </Box>

        <Box sx={{
              px: 4,
              py: 4,
              justifyContent: 'start',
              display: 'flex',
              maxWidth: 1440,
              width: '100%',
            }}>
          <Typography variant="h6">
            Want to get in touch? Contact us:&nbsp;
            <Link href="mailto:hello@voicetopics.com" color="purple.main" sx={{ textDecoration: 'none' }}>
              hello@voicetopics.com
            </Link>
          </Typography>
        </Box>

      </Stack>
    </Box>
  );
};

export default HomeView;
