
export const DEFAULT_VOICE_SET = 'default';

export const DEFAULT_VOICE = {
  source: 'openai',
  voice: 'echo',
};

export const NO_VOICE_ID = 'none';

export const VOICE_SET_LABELS = {
  default: 'Default',
  allEnglish: 'All English Voices',
  fullAccess: 'All Voices',
  customKevin: 'Custom (All + Kevin)',
  mines: 'Mines (EN + FR + ES + RU)',
  herAgenda: 'HerAgenda',
};

const ALL_OPENAI = [
  'openai/echo',
  'openai/shimmer',
  'openai/alloy',
  'openai/fable',
  'openai/onyx',
  'openai/nova',
  'openai/ash',
  'openai/coral',
  'openai/sage',
];

export const ENGLISH_POLLY = [
  'polly/Amy',
  'polly/Aria',
  'polly/Arthur',
  'polly/Ayanda',
  'polly/Brian',
  'polly/Danielle',
  'polly/Emma',
  'polly/Gregory',
  'polly/Ivy',
  'polly/Jasmine',
  'polly/Joanna',
  'polly/Joey',
  'polly/Justin',
  'polly/Kajal',
  'polly/Kendra',
  'polly/Kevin',
  'polly/Kimberly',
  'polly/Matthew',
  'polly/Niamh',
  'polly/Olivia',
  'polly/Ruth',
  'polly/Salli',
  'polly/Stephen'
];

export const SPANISH_POLLY = [
  'polly/Andres',
  'polly/Lucia',
  'polly/Lupe',
  'polly/Mia',
  'polly/Pedro',
  'polly/Sergio'
];

export const FRENCH_POLLY = [
  'polly/Gabrielle',
  'polly/Isabelle',
  'polly/Lea',
  'polly/Liam',
  'polly/Remi'
];

const ALL_POLLY = [
  ...ENGLISH_POLLY,
  ...SPANISH_POLLY,
  ...FRENCH_POLLY,
  'polly/Burcu',
  'polly/Jitka',
  'polly/Sabrina',
  'polly/Patrick',
  'polly/Alba',
  'polly/Raul',
  'polly/Elin',
  'polly/Ines',
  'polly/Vitoria',
  'polly/Camila',
  'polly/Ola',
  'polly/Lisa',
  'polly/Laura',
  'polly/Ida',
  'polly/Seoyeon',
  'polly/Kazuha',
  'polly/Tomoko',
  'polly/Takumi',
  'polly/Bianca',
  'polly/Vicki',
  'polly/Sofie',
  'polly/Zhiyu',
  'polly/Hala',
  'polly/Arlet',
  'polly/Hannah',
  'polly/Hiujin',
  'polly/Suvi',
  'polly/Daniel',
  'polly/Adriano',
  'polly/Thiago',
  'polly/Zayd',
];

export const ALL_ELEVENLABS = [
  'elevenlabs/Aria',
  'elevenlabs/Roger',
  'elevenlabs/Sarah',
  'elevenlabs/Laura',
  'elevenlabs/Charlie',
  'elevenlabs/George',
  'elevenlabs/Callum',
  'elevenlabs/River',
  'elevenlabs/Liam',
  'elevenlabs/Charlotte',
  'elevenlabs/Alice',
  'elevenlabs/Matilda',
  'elevenlabs/Will',
  'elevenlabs/Jessica',
  'elevenlabs/Eric',
  'elevenlabs/Chris',
  'elevenlabs/Brian',
  'elevenlabs/Daniel',
  'elevenlabs/Lily',
  'elevenlabs/Bill',
];

export const ENGLISH_GOOGLE = [
  'google/en-US-Casual-K',
  'google/en-US-Journey-D',
  'google/en-US-Journey-F',
  'google/en-US-Journey-O',
  'google/en-US-Neural2-A',
  'google/en-US-Neural2-C',
  'google/en-US-Neural2-D',
  'google/en-US-Neural2-E',
  'google/en-US-Neural2-F',
  'google/en-US-Neural2-G',
  'google/en-US-Neural2-H',
  'google/en-US-Neural2-I',
  'google/en-US-Neural2-J',
  'google/en-US-News-K',
  'google/en-US-News-L',
  'google/en-US-News-N',
  'google/en-US-Polyglot-1',
  'google/en-US-Standard-A',
  'google/en-US-Standard-B',
  'google/en-US-Standard-C',
  'google/en-US-Standard-D',
  'google/en-US-Standard-E',
  'google/en-US-Standard-F',
  'google/en-US-Standard-G',
  'google/en-US-Standard-H',
  'google/en-US-Standard-I',
  'google/en-US-Standard-J',
  'google/en-US-Wavenet-A',
  'google/en-US-Wavenet-B',
  'google/en-US-Wavenet-C',
  'google/en-US-Wavenet-D',
  'google/en-US-Wavenet-E',
  'google/en-US-Wavenet-F',
  'google/en-US-Wavenet-G',
  'google/en-US-Wavenet-H',
  'google/en-US-Wavenet-I',
  'google/en-US-Wavenet-J',
];

export const SPANISH_GOOGLE = [
  'google/es-ES-Journey-D',
  'google/es-ES-Journey-F',
  'google/es-ES-Journey-O',
  'google/es-ES-Neural2-A',
  'google/es-ES-Neural2-B',
  'google/es-ES-Neural2-C',
  'google/es-ES-Neural2-D',
  'google/es-ES-Neural2-E',
  'google/es-ES-Neural2-F',
  'google/es-ES-Neural2-G',
  'google/es-ES-Neural2-H',
  'google/es-ES-Neural2-I',
  'google/es-ES-Neural2-J',
  'google/es-ES-Polyglot-1',
  'google/es-ES-Standard-A',
  'google/es-ES-Standard-B',
  'google/es-ES-Standard-C',
  'google/es-ES-Standard-D',
  'google/es-ES-Standard-E',
  'google/es-ES-Standard-F',
  'google/es-ES-Standard-G',
  'google/es-ES-Standard-H',
  'google/es-ES-Studio-C',
  'google/es-ES-Studio-F',
  'google/es-ES-Wavenet-B',
  'google/es-ES-Wavenet-C',
  'google/es-ES-Wavenet-D',
  'google/es-ES-Wavenet-E',
  'google/es-ES-Wavenet-F',
  'google/es-ES-Wavenet-G',
  'google/es-ES-Wavenet-H',
  'google/es-US-Journey-D',
  'google/es-US-Journey-F',
  'google/es-US-Journey-O',
  'google/es-US-Neural2-A',
  'google/es-US-Neural2-B',
  'google/es-US-Neural2-C',
  'google/es-US-News-D',
  'google/es-US-News-E',
  'google/es-US-News-F',
  'google/es-US-News-G',
  'google/es-US-Polyglot-1',
  'google/es-US-Standard-A',
  'google/es-US-Standard-B',
  'google/es-US-Standard-C',
  'google/es-US-Studio-B',
  'google/es-US-Wavenet-A',
  'google/es-US-Wavenet-B',
  'google/es-US-Wavenet-C',
  'google/es-US-Wavenet-D',
  'google/es-US-Wavenet-E',
  'google/es-US-Wavenet-F',
  'google/es-US-Wavenet-G',
  'google/es-US-Wavenet-H',
];

export const FRENCH_GOOGLE = [
  'google/fr-CA-Journey-D',
  'google/fr-CA-Journey-F',
  'google/fr-CA-Journey-O',
  'google/fr-CA-Neural2-A',
  'google/fr-CA-Neural2-B',
  'google/fr-CA-Neural2-C',
  'google/fr-CA-Neural2-D',
  'google/fr-CA-Standard-A',
  'google/fr-CA-Standard-B',
  'google/fr-CA-Standard-C',
  'google/fr-CA-Standard-D',
  'google/fr-CA-Wavenet-A',
  'google/fr-CA-Wavenet-B',
  'google/fr-CA-Wavenet-C',
  'google/fr-CA-Wavenet-D',
  'google/fr-FR-Journey-D',
  'google/fr-FR-Journey-F',
  'google/fr-FR-Journey-O',
  'google/fr-FR-Neural2-A',
  'google/fr-FR-Neural2-B',
  'google/fr-FR-Neural2-C',
  'google/fr-FR-Neural2-D',
  'google/fr-FR-Neural2-E',
  'google/fr-FR-Neural2-F',
  'google/fr-FR-Neural2-G',
  'google/fr-FR-Polyglot-1',
  'google/fr-FR-Standard-A',
  'google/fr-FR-Standard-B',
  'google/fr-FR-Standard-C',
  'google/fr-FR-Standard-D',
  'google/fr-FR-Standard-E',
  'google/fr-FR-Standard-F',
  'google/fr-FR-Standard-G',
  'google/fr-FR-Studio-A',
  'google/fr-FR-Studio-D',
  'google/fr-FR-Wavenet-A',
  'google/fr-FR-Wavenet-B',
  'google/fr-FR-Wavenet-C',
  'google/fr-FR-Wavenet-D',
  'google/fr-FR-Wavenet-E',
  'google/fr-FR-Wavenet-F',
  'google/fr-FR-Wavenet-G',
];

export const RUSSIAN_GOOGLE = [
  'google/ru-RU-Standard-A',
  'google/ru-RU-Standard-B',
  'google/ru-RU-Standard-C',
  'google/ru-RU-Standard-D',
  'google/ru-RU-Standard-E',
  'google/ru-RU-Wavenet-A',
  'google/ru-RU-Wavenet-B',
  'google/ru-RU-Wavenet-C',
  'google/ru-RU-Wavenet-D',
  'google/ru-RU-Wavenet-E',
];

export const ALL_GOOGLE = [
  ...ENGLISH_GOOGLE,
  ...SPANISH_GOOGLE,
  ...FRENCH_GOOGLE,
  ...RUSSIAN_GOOGLE,
  'google/af-ZA-Standard-A',
  'google/am-ET-Standard-A',
  'google/am-ET-Standard-B',
  'google/am-ET-Wavenet-A',
  'google/am-ET-Wavenet-B',
  'google/ar-XA-Standard-A',
  'google/ar-XA-Standard-B',
  'google/ar-XA-Standard-C',
  'google/ar-XA-Standard-D',
  'google/ar-XA-Wavenet-A',
  'google/ar-XA-Wavenet-B',
  'google/ar-XA-Wavenet-C',
  'google/ar-XA-Wavenet-D',
  'google/bg-BG-Standard-A',
  'google/bg-BG-Standard-B',
  'google/bg-BG-Wavenet-B',
  'google/bn-IN-Standard-A',
  'google/bn-IN-Standard-B',
  'google/bn-IN-Standard-C',
  'google/bn-IN-Standard-D',
  'google/bn-IN-Wavenet-A',
  'google/bn-IN-Wavenet-B',
  'google/bn-IN-Wavenet-C',
  'google/bn-IN-Wavenet-D',
  'google/ca-ES-Standard-A',
  'google/ca-ES-Standard-B',
  'google/ca-ES-Wavenet-B',
  'google/cmn-CN-Standard-A',
  'google/cmn-CN-Standard-B',
  'google/cmn-CN-Standard-C',
  'google/cmn-CN-Standard-D',
  'google/cmn-CN-Wavenet-A',
  'google/cmn-CN-Wavenet-B',
  'google/cmn-CN-Wavenet-C',
  'google/cmn-CN-Wavenet-D',
  'google/cmn-TW-Standard-A',
  'google/cmn-TW-Standard-B',
  'google/cmn-TW-Standard-C',
  'google/cmn-TW-Wavenet-A',
  'google/cmn-TW-Wavenet-B',
  'google/cmn-TW-Wavenet-C',
  'google/cs-CZ-Standard-A',
  'google/cs-CZ-Standard-B',
  'google/cs-CZ-Wavenet-A',
  'google/cs-CZ-Wavenet-B',
  'google/da-DK-Neural2-D',
  'google/da-DK-Neural2-F',
  'google/da-DK-Standard-A',
  'google/da-DK-Standard-C',
  'google/da-DK-Standard-D',
  'google/da-DK-Standard-E',
  'google/da-DK-Standard-F',
  'google/da-DK-Standard-G',
  'google/da-DK-Wavenet-A',
  'google/da-DK-Wavenet-C',
  'google/da-DK-Wavenet-D',
  'google/da-DK-Wavenet-E',
  'google/da-DK-Wavenet-F',
  'google/da-DK-Wavenet-G',
  'google/de-DE-Journey-D',
  'google/de-DE-Journey-F',
  'google/de-DE-Journey-O',
  'google/de-DE-Neural2-A',
  'google/de-DE-Neural2-B',
  'google/de-DE-Neural2-C',
  'google/de-DE-Neural2-D',
  'google/de-DE-Neural2-F',
  'google/de-DE-Neural2-G',
  'google/de-DE-Neural2-H',
  'google/de-DE-Polyglot-1',
  'google/de-DE-Standard-A',
  'google/de-DE-Standard-B',
  'google/de-DE-Standard-C',
  'google/de-DE-Standard-D',
  'google/de-DE-Standard-E',
  'google/de-DE-Standard-F',
  'google/de-DE-Standard-G',
  'google/de-DE-Standard-H',
  'google/de-DE-Wavenet-A',
  'google/de-DE-Wavenet-B',
  'google/de-DE-Wavenet-C',
  'google/de-DE-Wavenet-D',
  'google/de-DE-Wavenet-E',
  'google/de-DE-Wavenet-F',
  'google/de-DE-Wavenet-G',
  'google/de-DE-Wavenet-H',
  'google/el-GR-Standard-A',
  'google/el-GR-Standard-B',
  'google/el-GR-Wavenet-A',
  'google/el-GR-Wavenet-B',
  'google/et-EE-Standard-A',
  'google/eu-ES-Standard-A',
  'google/eu-ES-Standard-B',
  'google/eu-ES-Wavenet-B',
  'google/fi-FI-Standard-A',
  'google/fi-FI-Standard-B',
  'google/fi-FI-Wavenet-A',
  'google/fi-FI-Wavenet-B',
  'google/fil-PH-Standard-A',
  'google/fil-PH-Standard-B',
  'google/fil-PH-Standard-C',
  'google/fil-PH-Standard-D',
  'google/fil-PH-Wavenet-A',
  'google/fil-PH-Wavenet-B',
  'google/fil-PH-Wavenet-C',
  'google/fil-PH-Wavenet-D',
  'google/fil-ph-Neural2-A',
  'google/fil-ph-Neural2-D',
  'google/gl-ES-Standard-A',
  'google/gl-ES-Standard-B',
  'google/gl-ES-Wavenet-B',
  'google/gu-IN-Standard-A',
  'google/gu-IN-Standard-B',
  'google/gu-IN-Standard-C',
  'google/gu-IN-Standard-D',
  'google/gu-IN-Wavenet-A',
  'google/gu-IN-Wavenet-B',
  'google/gu-IN-Wavenet-C',
  'google/gu-IN-Wavenet-D',
  'google/he-IL-Standard-A',
  'google/he-IL-Standard-B',
  'google/he-IL-Standard-C',
  'google/he-IL-Standard-D',
  'google/he-IL-Wavenet-A',
  'google/he-IL-Wavenet-B',
  'google/he-IL-Wavenet-C',
  'google/he-IL-Wavenet-D',
  'google/hi-IN-Neural2-A',
  'google/hi-IN-Neural2-B',
  'google/hi-IN-Neural2-C',
  'google/hi-IN-Neural2-D',
  'google/hi-IN-Standard-A',
  'google/hi-IN-Standard-B',
  'google/hi-IN-Standard-C',
  'google/hi-IN-Standard-D',
  'google/hi-IN-Standard-E',
  'google/hi-IN-Standard-F',
  'google/hi-IN-Wavenet-A',
  'google/hi-IN-Wavenet-B',
  'google/hi-IN-Wavenet-C',
  'google/hi-IN-Wavenet-D',
  'google/hi-IN-Wavenet-E',
  'google/hi-IN-Wavenet-F',
  'google/hu-HU-Standard-A',
  'google/hu-HU-Standard-B',
  'google/hu-HU-Wavenet-A',
  'google/hu-HU-Wavenet-B',
  'google/id-ID-Standard-A',
  'google/id-ID-Standard-B',
  'google/id-ID-Standard-C',
  'google/id-ID-Standard-D',
  'google/id-ID-Wavenet-A',
  'google/id-ID-Wavenet-B',
  'google/id-ID-Wavenet-C',
  'google/id-ID-Wavenet-D',
  'google/is-IS-Standard-A',
  'google/is-IS-Standard-B',
  'google/is-IS-Wavenet-B',
  'google/it-IT-Journey-D',
  'google/it-IT-Journey-F',
  'google/it-IT-Journey-O',
  'google/it-IT-Neural2-A',
  'google/it-IT-Neural2-C',
  'google/it-IT-Neural2-F',
  'google/it-IT-Standard-A',
  'google/it-IT-Standard-B',
  'google/it-IT-Standard-C',
  'google/it-IT-Standard-D',
  'google/it-IT-Standard-E',
  'google/it-IT-Standard-F',
  'google/it-IT-Wavenet-A',
  'google/it-IT-Wavenet-B',
  'google/it-IT-Wavenet-C',
  'google/it-IT-Wavenet-D',
  'google/it-IT-Wavenet-E',
  'google/it-IT-Wavenet-F',
  'google/ja-JP-Neural2-B',
  'google/ja-JP-Neural2-C',
  'google/ja-JP-Neural2-D',
  'google/ja-JP-Standard-A',
  'google/ja-JP-Standard-B',
  'google/ja-JP-Standard-C',
  'google/ja-JP-Standard-D',
  'google/ja-JP-Wavenet-A',
  'google/ja-JP-Wavenet-B',
  'google/ja-JP-Wavenet-C',
  'google/ja-JP-Wavenet-D',
  'google/kn-IN-Standard-A',
  'google/kn-IN-Standard-B',
  'google/kn-IN-Standard-C',
  'google/kn-IN-Standard-D',
  'google/kn-IN-Wavenet-A',
  'google/kn-IN-Wavenet-B',
  'google/kn-IN-Wavenet-C',
  'google/kn-IN-Wavenet-D',
  'google/ko-KR-Neural2-A',
  'google/ko-KR-Neural2-B',
  'google/ko-KR-Neural2-C',
  'google/ko-KR-Standard-A',
  'google/ko-KR-Standard-B',
  'google/ko-KR-Standard-C',
  'google/ko-KR-Standard-D',
  'google/ko-KR-Wavenet-A',
  'google/ko-KR-Wavenet-B',
  'google/ko-KR-Wavenet-C',
  'google/ko-KR-Wavenet-D',
  'google/lt-LT-Standard-A',
  'google/lt-LT-Standard-B',
  'google/lt-LT-Wavenet-B',
  'google/lv-LV-Standard-A',
  'google/lv-LV-Standard-B',
  'google/lv-LV-Wavenet-B',
  'google/ml-IN-Standard-A',
  'google/ml-IN-Standard-B',
  'google/ml-IN-Standard-C',
  'google/ml-IN-Standard-D',
  'google/ml-IN-Wavenet-A',
  'google/ml-IN-Wavenet-B',
  'google/ml-IN-Wavenet-C',
  'google/ml-IN-Wavenet-D',
  'google/mr-IN-Standard-A',
  'google/mr-IN-Standard-B',
  'google/mr-IN-Standard-C',
  'google/mr-IN-Wavenet-A',
  'google/mr-IN-Wavenet-B',
  'google/mr-IN-Wavenet-C',
  'google/ms-MY-Standard-A',
  'google/ms-MY-Standard-B',
  'google/ms-MY-Standard-C',
  'google/ms-MY-Standard-D',
  'google/ms-MY-Wavenet-A',
  'google/ms-MY-Wavenet-B',
  'google/ms-MY-Wavenet-C',
  'google/ms-MY-Wavenet-D',
  'google/nb-NO-Standard-A',
  'google/nb-NO-Standard-B',
  'google/nb-NO-Standard-C',
  'google/nb-NO-Standard-D',
  'google/nb-NO-Standard-E',
  'google/nb-NO-Standard-F',
  'google/nb-NO-Standard-G',
  'google/nb-NO-Wavenet-A',
  'google/nb-NO-Wavenet-B',
  'google/nb-NO-Wavenet-C',
  'google/nb-NO-Wavenet-D',
  'google/nb-NO-Wavenet-E',
  'google/nb-NO-Wavenet-F',
  'google/nb-NO-Wavenet-G',
  'google/nl-BE-Standard-A',
  'google/nl-BE-Standard-B',
  'google/nl-BE-Standard-C',
  'google/nl-BE-Standard-D',
  'google/nl-BE-Wavenet-A',
  'google/nl-BE-Wavenet-B',
  'google/nl-BE-Wavenet-C',
  'google/nl-BE-Wavenet-D',
  'google/nl-NL-Standard-A',
  'google/nl-NL-Standard-B',
  'google/nl-NL-Standard-C',
  'google/nl-NL-Standard-D',
  'google/nl-NL-Standard-E',
  'google/nl-NL-Standard-F',
  'google/nl-NL-Standard-G',
  'google/nl-NL-Wavenet-A',
  'google/nl-NL-Wavenet-B',
  'google/nl-NL-Wavenet-C',
  'google/nl-NL-Wavenet-D',
  'google/nl-NL-Wavenet-E',
  'google/nl-NL-Wavenet-F',
  'google/nl-NL-Wavenet-G',
  'google/pa-IN-Standard-A',
  'google/pa-IN-Standard-B',
  'google/pa-IN-Standard-C',
  'google/pa-IN-Standard-D',
  'google/pa-IN-Wavenet-A',
  'google/pa-IN-Wavenet-B',
  'google/pa-IN-Wavenet-C',
  'google/pa-IN-Wavenet-D',
  'google/pl-PL-Standard-A',
  'google/pl-PL-Standard-B',
  'google/pl-PL-Standard-C',
  'google/pl-PL-Standard-D',
  'google/pl-PL-Standard-E',
  'google/pl-PL-Standard-F',
  'google/pl-PL-Standard-G',
  'google/pl-PL-Wavenet-A',
  'google/pl-PL-Wavenet-B',
  'google/pl-PL-Wavenet-C',
  'google/pl-PL-Wavenet-D',
  'google/pl-PL-Wavenet-E',
  'google/pl-PL-Wavenet-F',
  'google/pl-PL-Wavenet-G',
  'google/pt-BR-Neural2-A',
  'google/pt-BR-Neural2-B',
  'google/pt-BR-Neural2-C',
  'google/pt-BR-Standard-A',
  'google/pt-BR-Standard-B',
  'google/pt-BR-Standard-C',
  'google/pt-BR-Standard-D',
  'google/pt-BR-Standard-E',
  'google/pt-BR-Wavenet-A',
  'google/pt-BR-Wavenet-B',
  'google/pt-BR-Wavenet-C',
  'google/pt-BR-Wavenet-D',
  'google/pt-BR-Wavenet-E',
  'google/pt-PT-Standard-A',
  'google/pt-PT-Standard-B',
  'google/pt-PT-Standard-C',
  'google/pt-PT-Standard-D',
  'google/pt-PT-Standard-E',
  'google/pt-PT-Standard-F',
  'google/pt-PT-Wavenet-A',
  'google/pt-PT-Wavenet-B',
  'google/pt-PT-Wavenet-C',
  'google/pt-PT-Wavenet-D',
  'google/pt-PT-Wavenet-E',
  'google/pt-PT-Wavenet-F',
  'google/ro-RO-Standard-A',
  'google/ro-RO-Standard-B',
  'google/ro-RO-Wavenet-A',
  'google/ro-RO-Wavenet-B',
  'google/sk-SK-Standard-A',
  'google/sk-SK-Standard-B',
  'google/sk-SK-Wavenet-A',
  'google/sk-SK-Wavenet-B',
  'google/sr-RS-Standard-A',
  'google/sv-SE-Standard-A',
  'google/sv-SE-Standard-B',
  'google/sv-SE-Standard-C',
  'google/sv-SE-Standard-D',
  'google/sv-SE-Standard-E',
  'google/sv-SE-Standard-F',
  'google/sv-SE-Standard-G',
  'google/sv-SE-Wavenet-A',
  'google/sv-SE-Wavenet-B',
  'google/sv-SE-Wavenet-C',
  'google/sv-SE-Wavenet-D',
  'google/sv-SE-Wavenet-E',
  'google/sv-SE-Wavenet-F',
  'google/sv-SE-Wavenet-G',
  'google/ta-IN-Standard-A',
  'google/ta-IN-Standard-B',
  'google/ta-IN-Standard-C',
  'google/ta-IN-Standard-D',
  'google/ta-IN-Wavenet-A',
  'google/ta-IN-Wavenet-B',
  'google/ta-IN-Wavenet-C',
  'google/ta-IN-Wavenet-D',
  'google/te-IN-Standard-A',
  'google/te-IN-Standard-B',
  'google/te-IN-Standard-C',
  'google/te-IN-Standard-D',
  'google/th-TH-Neural2-C',
  'google/th-TH-Standard-A',
  'google/tr-TR-Standard-A',
  'google/tr-TR-Standard-B',
  'google/tr-TR-Standard-C',
  'google/tr-TR-Standard-D',
  'google/tr-TR-Standard-E',
  'google/tr-TR-Wavenet-A',
  'google/tr-TR-Wavenet-B',
  'google/tr-TR-Wavenet-C',
  'google/tr-TR-Wavenet-D',
  'google/tr-TR-Wavenet-E',
  'google/uk-UA-Standard-A',
  'google/uk-UA-Wavenet-A',
  'google/ur-IN-Standard-A',
  'google/ur-IN-Standard-B',
  'google/ur-IN-Wavenet-A',
  'google/ur-IN-Wavenet-B',
  'google/vi-VN-Neural2-A',
  'google/vi-VN-Neural2-D',
  'google/vi-VN-Standard-A',
  'google/vi-VN-Standard-B',
  'google/vi-VN-Standard-C',
  'google/vi-VN-Standard-D',
  'google/vi-VN-Wavenet-A',
  'google/vi-VN-Wavenet-B',
  'google/vi-VN-Wavenet-C',
  'google/vi-VN-Wavenet-D',
  'google/yue-HK-Standard-A',
  'google/yue-HK-Standard-B',
  'google/yue-HK-Standard-C',
  'google/yue-HK-Standard-D'
];

export const VOICE_OPTIONS = {
  default: [
    ...ALL_OPENAI,
    'polly/Danielle',
    'polly/Gregory',
    'polly/Ruth',
  ],

  allEnglish: [
    ...ALL_OPENAI,
    ...ALL_ELEVENLABS,
    ...ENGLISH_GOOGLE,
    ...ENGLISH_POLLY,
  ],

  fullAccess: [
    ...ALL_OPENAI,
    ...ALL_POLLY,
    ...ALL_GOOGLE,
    ...ALL_ELEVENLABS,
  ],

  customKevin: [
    'elevenlabs/Kevin A',
    ...ALL_OPENAI,
    ...ALL_POLLY,
    ...ALL_GOOGLE,
    ...ALL_ELEVENLABS,
  ],

  mines: [
    ...ALL_OPENAI,
    ...ALL_ELEVENLABS,
    ...ENGLISH_POLLY,
    ...FRENCH_POLLY,
    ...SPANISH_POLLY,
    ...ENGLISH_GOOGLE,
    ...FRENCH_GOOGLE,
    ...SPANISH_GOOGLE,
    ...RUSSIAN_GOOGLE,
  ],

  herAgenda: [
    'polly/Danielle',
    'polly/Gregory',
    'polly/Aria',
    'polly/Joanna',
    'elevenlabs/Sarah',
    'elevenlabs/Jessica',
  ],
};

export function toVoiceId(options = {}) {
  const { voice, source } = options;
  if (!voice) return NO_VOICE_ID;
  return [source, voice].join('/');
}

export function fromVoiceId(voiceId) {
  if (!voiceId) return {};

  const [ source, voice ] = voiceId.split('/');
  return { source, voice };
}

export function getDefaultVoice(organization) {
  if (organization?.options?.voice && organization?.options?.source) {
    return {
      voice: organization.options.voice,
      source: organization.options.source,
    };
  }
  return DEFAULT_VOICE;
}

