import { useEffect, useState, useContext, useRef } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { timeOperation } from './common.js';
import { CenterBox } from './components.js';
import { Messages } from './context.js';

import ContentPlayer from './content/ContentPlayer.js';
import ContentPreview from './content/ContentPreview.js';
import MiniPlayer from './content/MiniPlayer.js';

const PublicListenView = () => {

  const { setMessage } = useContext(Messages);

  const [searchParams] = useSearchParams();
  const style = searchParams.get('style');
  const groupId = searchParams.get('groupId');

  const { id } = useParams();

  const [ activeContent, setActiveContent ] = useState(null);
  const [ contentList, setContentList ] = useState(null);
  const [ contentLoading, setContentLoading ] = useState(false);
  const [ organization, setOrganization ] = useState(null);
  const activeContentRef = useRef();
  const [ adList, setAdList ] = useState(null);

  const handleSwapActive = (newActiveContent, play) => {
    if (newActiveContent.id === activeContent.id) return;

    setContentList(contentList
        .concat([activeContent])
        .filter(c => c.id !== newActiveContent.id)
        .toSorted((a, b) => a.publishDate < b.publishDate ? 1 : -1));
    window.history.pushState(null, '', `#/listen/${newActiveContent.id}`);
    setActiveContent(newActiveContent);
    setTimeout(() => {
      if (play) {
        activeContentRef.current.play();
      }
    }, 100);
  };

  useEffect(() => {
    async function streamContentList() {
      if (!id || contentLoading || contentList) return;

      setContentLoading(true);
      try {
        const contentStream = id.startsWith('org')
            ? new EventSource(`/api/organization/${id}/listen${groupId ? `?groupId=${groupId}` : ''}`)
            : new EventSource(`/api/content/${id}/listen`);

        const [loadTime] = await timeOperation(() => new Promise((resolve, reject) => {
          contentStream.onerror = (error) => {
            contentStream.close();
            reject(error);
          };
          contentStream.addEventListener('exception', (event) => {
            contentStream.close();
            reject(Error(event.data));
          });
          contentStream.addEventListener('activeContentItem', (event) => {
            const { activeContentItem } = JSON.parse(event.data);

            setActiveContent(activeContentItem);
          });
          contentStream.addEventListener('activeOrganization', (event) => {
            const { activeOrganization } = JSON.parse(event.data);
            setOrganization(activeOrganization);
          });
          contentStream.addEventListener('contentItems', (event) => {
            let { contentItems } = JSON.parse(event.data);
            setContentList(prevContentList => {
              const newItems = contentItems.filter(c => !prevContentList?.some(p => p.id === c.id));
              return (prevContentList ?? []).concat(newItems)
            });
          });
          contentStream.addEventListener('done', (event) => {
            contentStream.close();
            resolve();
          });
        }));
        mixpanel.track('Streamed public content', { loadTime, organizationId: organization?.id });
      } catch (e) {
        console.error(e);
        const errorMessage = 'Failed to stream content.';
        setMessage({ children: errorMessage, severity: 'error' });
        mixpanel.track('Error streaming public content', { errorMessage, organizationId: organization?.id });
      } finally {
        setContentLoading(false);
      }
    }
    streamContentList();
  }, [id, groupId, setMessage]);

  useEffect(() => {
    async function streamAdList() {
      if (!activeContent?.id) return;

      try {
        const adStream = new EventSource(`/api/ads/${activeContent.id}/listen`);

        let adItems = [];
        const [loadTime] = await timeOperation(() => new Promise((resolve, reject) => {
          adStream.onerror = (error) => {
            adStream.close();
            reject(error);
          };
          adStream.addEventListener('exception', (event) => {
            adStream.close();
            reject(Error(event.data));
          });
          adStream.addEventListener('ads', (event) => {
            const { ads: newAds } = JSON.parse(event.data);
            adItems = adItems.concat(newAds);
            setAdList(adItems);
          });
          adStream.addEventListener('done', (event) => {
            adStream.close();
            resolve();
          });
        }));
        mixpanel.track('Streamed ads', { loadTime, organizationId: organization?.id });
      } catch (e) {
        const errorMessage = 'Failed to stream ads.';
        setMessage({ children: errorMessage, severity: 'error' });
        mixpanel.track('Error streaming ads', { errorMessage, organizationId: organization?.id });
      }
    }
    streamAdList();
  }, [activeContent, setMessage]);

  //console.log({ contentLoading, contentList, activeContent, adList });

  useEffect(() => {
    if (organization) {
      mixpanel.register({
        organizationId: organization.id,
      });
    }
  }, [organization]);

  if (!organization) return (
    <CenterBox>
      <CircularProgress size={64} aria-label="Loading content" />
    </CenterBox>
  );

  if (style === 'minimal') {
    return (
      <Stack direction="column"
          role="main"
          aria-label="Audio content player"
          sx={{
            alignItems: 'center',
          }}
          spacing={1}
          data-testid="public-listen-view-minimal">
        {activeContent && <MiniPlayer content={activeContent}
              ads={adList}
              publicMode={true}
              ref={activeContentRef} />}
        {contentLoading && <CircularProgress size={32} aria-label="Loading content" />}
      </Stack>
    );
  }

  return (
    <Box
        component="main"
        role="main"
        aria-label="Audio content player"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          width: '20rem',
          height: '100%',
          margin: 'auto',
        }}
        data-testid="public-listen-view">
      {style !== 'compact' && <Stack direction="column" sx={{ width: '100%', alignItems: 'center', py: 4 }}>
        <Typography variant="h1" sx={{ fontSize: 25, fontWeight: 600 }}>{organization.name}</Typography>
        <Typography sx={{ textWrap: 'nowrap', opacity: 0.7 }}>
          powered by&nbsp;
          <Link href="https://voicetopics.com"
              target="_blank"
              rel="noopener"
              aria-label="Visit VoiceTopics website"
              sx={{ fontWeight: 600 }}>
            VoiceTopics
          </Link>
        </Typography>
      </Stack>}
      <Stack direction="column"
          role="region"
          aria-label="Audio content list"
          sx={{
            width: '100%',
            alignItems: 'center',
            px: 1,
          }} spacing={1}>
        <Box sx={{ width: '100%', borderBottom: '1px solid', borderColor: 'divider', pt: style === 'compact' && 4 }}>
          {activeContent && <ContentPlayer content={activeContent}
                key={activeContent.id}
                ads={adList}
                dateMode="publishDate"
                gridMode={true}
                publicMode={true}
                ref={activeContentRef} />}
        </Box>
        {style !== 'compact' && contentList?.map((contentItem) => (
          <ContentPreview key={contentItem.id}
              content={contentItem}
              onSwap={(play) => handleSwapActive(contentItem, play)} />
        ))}
        {!contentLoading && !activeContent && !(contentList?.length > 0)
              && <Box role="status" aria-live="polite">No content published.</Box>}
        {contentLoading && <CircularProgress size={32} aria-label="Loading content" />}
      </Stack>
    </Box>
  );
};

export default PublicListenView;
