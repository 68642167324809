
// TODO: Update all colors to match mocks
export const publishStatusList = ['PUBLISHED', 'UNPUBLISHED'];
export const publishStatusColors = {
  UNPUBLISHED: 'inProgress',
  PUBLISHED: 'completed',
};

export const TERMS_LAST_UPDATED = '2024-10-31T00:00:00.000Z';

export const creationStatusList = ['DRAFT', 'ARCHIVED', 'COMPLETED', 'DELETED'];
export const creationStatusColors = {
  DRAFT: 'gray',
  ARCHIVED: 'gray',
  COMPLETED: 'gray',
  DELETED: 'failed',
};

export const processingStatusList = ['PROCESSING', 'COMPLETED', 'ERROR'];
export const processingStatusMap = {
  REQUESTED: 'PROCESSING',
  SCRAPED: 'PROCESSING',
  EDITED: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
};

export const NO_TITLE = 'Untitled';
export const NO_CREATOR = 'Unknown';
export const NO_GROUP = 'Unassigned';

export const NO_GROUP_ID = 'none';
export const NO_LANGUAGE_ID = 'none';

export const TTS_SOURCES = {
  openai: 'OpenAI',
  google: 'Google',
  polly: 'AWS Polly',
  elevenlabs: 'ElevenLabs',
};

export const EMAIL_REGEX = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,63}$/; // eslint-disable-line

export const SAMPLE_AD = {
      id: 'ad-41514621ac9d',
      metadata: { title: 'Sample Ad' },
      audio: {
        uri: 'https://s3.us-east-1.amazonaws.com/voice-topics-audio-bucket-dev/audio-913ac68e7a8b.mp3',
        duration: 1.704,
      },
    };

