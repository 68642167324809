import { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { fetchUserInfo, urlWith } from '../common.js';
import { UserInfo, Messages } from '../context.js';
import { ActionButton } from '../components.js';

import SimpleHeaderBar from '../SimpleHeaderBar.js';

const SecretLoginView = () => {
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const { setMessage } = useContext(Messages);
  const { secretKey } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userInfo && !userInfo.unauthenticated) {
      const originalTarget = new URL(window.location).searchParams.get('originalTarget');
      window.location.href =
          originalTarget ?? urlWith(window.location, { pathname: '/app', hash: '/' }).toString();
    }
  }, [ userInfo ]);

  async function loginWithSecretKey() {
    if (!secretKey) return;

    setLoading(true);
    const response = await fetch(`/auth/link?secretKey=${secretKey}`, { method: 'POST' });
    if (response.ok) {
      const token = await response.json();
      window.location.href = token.originalTarget
          ?? urlWith(window.location, { pathname: '/app', hash: '/' }).toString();
      setUserInfo(await fetchUserInfo());
    } else {
      const errorMessage = await response.text();
      setMessage({ children: errorMessage, severity: 'error' });
      setLoading(false);
      navigate('/login');
    }
  }

  return (
    <Stack direction="column"
        sx={{ width: '100%', alignItems: 'center', minWidth: 1000, height: '100%' }}
        spacing={6}>

      <SimpleHeaderBar />

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {!secretKey ? (
          <Stack direction="column" spacing={2} sx={{ width: 'fit-content', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight={700}>Invalid Login Link</Typography>
            <Typography variant="body2">
              This login link appears to be invalid or has expired.
            </Typography>
          </Stack>
        ) : loading ? (
          <Stack direction="column" spacing={2} sx={{ width: 'fit-content', alignItems: 'center' }}>
            <CircularProgress size={64} />
          </Stack>
        ) : (
          <Stack direction="column" spacing={2} sx={{ width: 'fit-content', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight={700}>Login</Typography>
            <Typography variant="body2">
              Click here to log in to your VoiceTopics account.
            </Typography>
            <ActionButton onClick={loginWithSecretKey} data-testid="login-button">
              <Stack direction="row" spacing={2} sx={{ px: 3 }}>
                <Typography fontWeight={700}>Login</Typography>
              </Stack>
            </ActionButton>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default SecretLoginView;
