/**
 * Collection of array utility functions
 */

/**
 * Sum all elements in an array
 * @param {Array<number>} arr - Array of numbers to sum
 * @returns {number} - Sum of all elements
 */
export function sum(arr) {
  return arr?.reduce((a, b) => a + b, 0) ?? 0;
}

/**
 * Multiply all elements in an array
 * @param {Array<number>} arr - Array of numbers to multiply
 * @returns {number} - Product of all elements
 */
export function product(arr) {
  return arr?.reduce((a, b) => a * b, 1) ?? 1;
}

/**
 * Concatenate arrays together
 * @param {Array<Array>} arr - Array of arrays to concatenate
 * @returns {Array} - Concatenated array
 */
export function concatSum(arr) {
  return arr?.reduce((a, b) => a.concat(b), []) ?? [];
}

/**
 * Find maximum element in array based on mapping function
 * @param {Array} arr - Source array
 * @param {Function} f - Mapping function to determine comparison value
 * @returns {*} - Maximum element
 */
export function maxBy(arr, f) {
  if (!arr || arr.length === 0) return undefined;
  return arr.reduce((a, b) => f(a) > f(b) ? a : b);
}

/**
 * Find maximum element in numeric array
 * @param {Array<number>} arr - Array of numbers
 * @returns {number} - Maximum value
 */
export function max(arr) {
  return maxBy(arr, i => i);
}

/**
 * Find minimum element in array based on mapping function
 * @param {Array} arr - Source array
 * @param {Function} f - Mapping function to determine comparison value
 * @returns {*} - Minimum element
 */
export function minBy(arr, f) {
  if (arr.length === 0) return undefined;
  return arr.reduce((a, b) => f(a) < f(b) ? a : b);
}

/**
 * Find minimum element in numeric array
 * @param {Array<number>} arr - Array of numbers
 * @returns {number} - Minimum value
 */
export function min(arr) {
  return minBy(arr, i => i);
}

/**
 * Check if two arrays have the same elements in the same order
 * @param {Array} arr - First array
 * @param {Array} other - Second array
 * @returns {boolean} - True if arrays are equal
 */
export function arrayEq(arr, other) {
  return arr && arr.length === other?.length && arr.every((el, i) => el === other[i]);
}

/**
 * Group array elements by key function
 * @param {Array} arr - Source array
 * @param {Function} f - Function to determine group key for each element
 * @returns {Object} - Object with keys mapped to arrays of elements
 */
export function groupBy(arr, f) {
  if (!arr) return {};
  
  const result = {};
  for (let elem of arr) {
    const key = f(elem);
    result[key] = (result[key] || []).concat([elem]);
  }
  return result;
} 