import {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
  useCallback,
}
from 'react';
import mixpanel from 'mixpanel-browser';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { renderDuration, createId } from '../common.js';
import { SimpleButton, CenterBox } from '../components.js';
import { Messages, Audio } from '../context.js';
import { PlayIcon, ForwardIcon, RewindIcon, PauseIcon } from '../icons.js';
import { sum } from '../../../src/arrayUtils.js';

const sectionPosition = (position, durations) => {
  if (isNaN(position)) return { position: 0, index: 0 };

  let index = 0;
  while (position > durations[index]
      && index < durations.length - 1) {
    position -= durations[index];
    index++;
  }
  return { position, index };
};

const AudioPlayer = forwardRef(({
      sources,
      ads,
      finalAdCount,
      preload,
      setOverrideTitle,
      contentId,
      organizationId,
      publicMode,
      displayMode,
      preClickLabel = 'Listen to this article',
      seekbarColor = 'black',
      sx,
    }, ref) => {

  const { setMessage } = useContext(Messages);
  const { play, stop, playing, playbackSpeed } = useContext(Audio);

  const id = useMemo(() => createId(), []);

  const duration = useMemo(() => sources && sum(sources.map(s => s.duration)), [ sources ]);

  // This stores the global position within the audio, in seconds. Contrast with
  // audioRef.current.currentTime, which stores position within one audio section or ad.
  const [position, setPosition] = useState(0);
  const audioRef = useRef(null);

  // This stores state information related to navigating between audio sources, including ads.
  // In publicMode, adMode is triggered when sectionIndex > adIndex
  // The currently active audio segment is sources.find(s => s.sectionIndex === adState.sectionIndex)
  // The currently active ad is ads[adState.adIndex % ads.length]
  const [adState, setAdState] = useState({ adMode: false, sectionIndex: 0, adIndex: 0 });

  const [preClick, setPreClick] = useState(displayMode === 'minimal');

  const adTimes = useMemo(() => {
    const result = [];
    for (let i = 0; i < finalAdCount; i++) {
      result.push(sum(sources.filter(s => (s.sectionIndex ?? 0) <= i).map(s => s.duration)));
    }
    return result;
  }, [ sources, finalAdCount ]);

  //useEffect(() => console.log({ sources, finalAdCount, ads, adTimes }), [sources, finalAdCount, ads, adTimes]);

  const handlePlay = useCallback((event) => {
    event?.stopPropagation();
    mixpanel.track('Play audio', {
      currentTime: position,
      contentId,
      organizationId,
    });
    play({ audioSource: audioRef.current, type: 'element', id });
    setPreClick(false);
  }, [id, play, contentId, organizationId, position]);

  const handleResume = useCallback(() => {
    play({ audioSource: audioRef.current, type: 'element', id });
  }, [id, play]);

  const handleSeek = useCallback((targetTime, event) => {
    if (isNaN(duration)) return;

    event?.stopPropagation();

    const newTime = Math.max(Math.min(targetTime, duration), 0);

    mixpanel.track('Seek audio', {
      targetTime: newTime,
      contentId,
      organizationId,
    });

    const { position: currentTime, index: newActiveSourceIndex } = sectionPosition(
        newTime, sources.map(s => s.duration));
    const newActiveSectionIndex = sources[newActiveSourceIndex].sectionIndex ?? 0;

    //console.log({ handleSeek: true, newTime, currentTime, newActiveSourceIndex, newActiveSectionIndex, targetTime, duration, publicMode, adState });

    setPosition(newTime);

    // In publicMode, we trigger adMode based on how many ads the user has viewed and what
    // section they are trying to listen to. In non-publicMode, we never trigger adMode based on
    // seeking.
    if (ads?.length > 0
        && adTimes?.length > 0
        && newActiveSectionIndex > adState.adIndex
        && publicMode) {
      //console.log('Trigger ad mode');
      setAdState({ ...adState, adMode: true, sectionIndex: newActiveSectionIndex });
    } else {
      //console.log('Jump to the selected time');
      setAdState({ ...adState, adMode: false, sectionIndex: newActiveSectionIndex });

      if (audioRef.current) {
        if (adState.sectionIndex === newActiveSectionIndex) {
          // Update currentTime immediately
          audioRef.current.currentTime = currentTime;
        } else {
          // Update currentTime when the audio component has loaded the new audio
          audioRef.current.addEventListener('loadeddata', () => {
            audioRef.current.currentTime = currentTime;
          }, { once: true });
        }
      }
    }

    if (playing?.id === id) {
      setTimeout(() => handleResume(), 0);
    }
  }, [
    adState,
    duration,
    id,
    playing,
    sources,
    ads,
    adTimes,
    handleResume,
    contentId,
    organizationId,
    publicMode,
  ]);

  const handleProgress = (event) => {
    if (!adState.adMode && event.target.currentTime !== 0) {
      const activeSourceIndex = sources.findIndex(s => (s.sectionIndex ?? 0) === adState.sectionIndex);
      const overallTime = Math.min(
          event.target.currentTime + sum(sources.slice(0, activeSourceIndex).map(s => s.duration)),
          duration);
      //console.log({ handleProgress: true, activeSourceIndex, adState, overallTime, currentTime: event.target.currentTime });
      setPosition(overallTime);
    }
  };

  const handlePause = () => {
    mixpanel.track('Pause audio', {
      currentTime: position,
      contentId,
      organizationId,
    });
    stop({ id });
  };

  const handleEnded = (event) => {
    let done = false;

    const sourceIndex = sources.findIndex(s => (s.sectionIndex ?? 0) === adState.sectionIndex);

    //console.log({ handleEnded: 'start', adState, finalAdCount, sources, sourceIndex });

    if (adState.adMode) {
      mixpanel.track('Completed ad', {
        contentId,
        adId: ads[adState.adIndex % ads.length]?.id,
        organizationId,
      });

      const newActiveAdIndex = adState.adIndex + 1;

      // Check if we have heard enough ads.
      if (adState.sectionIndex <= newActiveAdIndex) {
        setAdState({ ...adState, adMode: false, adIndex: newActiveAdIndex });

        const activeSourceIndex = sources.findIndex(s => (s.sectionIndex ?? 0) === adState.sectionIndex);

        if (activeSourceIndex >= 0) {
          const { position: currentTime, index: newActiveSourceIndex } =
              sectionPosition(position, sources.map(s => s.duration));
          if (audioRef.current && activeSourceIndex === newActiveSourceIndex) {
            // Resume at the right position in the section
            audioRef.current.addEventListener('loadeddata', () => {
              audioRef.current.currentTime = currentTime;
            }, { once: true });
          }
          //console.log({ handleEnded: 'resumeContent', newActiveAdIndex, currentTime });
        } else {
          done = true;
          setAdState({ adMode: false, sectionIndex: 0, adIndex: newActiveAdIndex });
          setPosition(0);
          //console.log({ handleEnded: 'done' });
        }
      } else {
        //console.log({ handleEnded: 'anotherAd', newActiveAdIndex });
        setAdState({ ...adState, adIndex: newActiveAdIndex });
      }
    } else {
      // Advance activeSectionIndex to the next source, OR to the end, if we are out of
      // sources.
      const newActiveSectionIndex = sourceIndex < sources.length - 1
          ? sources[sourceIndex + 1].sectionIndex ?? 0
          : Math.max(finalAdCount ?? 0, ((sources[sources.length - 1].sectionIndex ?? 0) + 1));

      // In publicMode, a user only hears an ad at each position once. In non-publicMode, a user
      // may hear an ad at each position multiple times.
      let activeAdIndex = adState.adIndex;
      if (!publicMode) {
        // Reset activeAdIndex so that the appropriate ad position is shown on the seekbar
        //console.log('Reset activeAdIndex for non-publicMode');
        activeAdIndex = sources[sourceIndex].sectionIndex ?? 0;
      }

      if (((activeAdIndex >= (finalAdCount ?? 0)) || ads?.length === 0)
          && newActiveSectionIndex > (sources[sources.length - 1].sectionIndex ?? 0)) {
        done = true;
        setAdState({ adMode: false, sectionIndex: 0, adIndex: activeAdIndex });
        setPosition(0);
        //console.log({ handleEnded: 'done' });
      } else if (!ads?.length) {
        //console.log({ handleEnded: 'missingAd', newActiveSectionIndex });
        setAdState({ adMode: false, adIndex: activeAdIndex + 1, sectionIndex: newActiveSectionIndex });
      } else if (activeAdIndex >= newActiveSectionIndex) {
        //console.log({ handleEnded: 'skipAd', newActiveSectionIndex, sources });
        setAdState({ adMode: false, sectionIndex: newActiveSectionIndex, adIndex: activeAdIndex });
      } else {
        mixpanel.track('Started ad', { contentId, adId: ads[activeAdIndex % ads.length].id, organizationId });
        //console.log({ handleEnded: 'startAd', adState });
        setAdState({ adMode: true, sectionIndex: newActiveSectionIndex, adIndex: activeAdIndex });
      }
    }

    if (!done) {
      handleResume();
    }
  };

  useImperativeHandle(ref, () => ({
    play: handlePlay,
    pause: handlePause,
    seek: handleSeek,
  }));

  // Handle keyboard shortcuts for audio player
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Only process keyboard shortcuts when this player is active or if this is the only player
      if (playing?.id !== id) return;

      if (document.activeElement &&
         ['input', 'textarea', 'select'].includes(document.activeElement.tagName.toLowerCase())) {
        return; // Don't process shortcuts when typing in form elements
      }

      switch (e.key) {
        case ' ': // Space
          e.preventDefault();
          if (playing?.id === id) {
            handlePause();
          } else {
            handlePlay(e);
          }
          break;
        case 'ArrowRight': // Right arrow
          e.preventDefault();
          if (!adState.adMode) {
            handleSeek(position + 10, e);
          }
          break;
        case 'ArrowLeft': // Left arrow
          e.preventDefault();
          if (!adState.adMode) {
            handleSeek(position - 10, e);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [id, playing, handlePlay, handlePause, handleSeek, position, adState.adMode]);

  const handleError = (event) => {
    console.error(event.target.error);
    setMessage({ children: `MediaError code ${event.target.error?.code}`, severity: 'error' });
  };

  useEffect(() => {
    if (adState.adMode) {
      setOverrideTitle?.(ads[adState.adIndex % ads.length]?.metadata?.title);
    } else {
      setOverrideTitle?.(null);
    }
  }, [ adState, ads, setOverrideTitle ]);

  useEffect(() => {
    const playbackRate = adState.adMode ? 1 : playbackSpeed;

    if (audioRef.current.readyState >= 2) {
      // Set playbackRate immediately
      audioRef.current.playbackRate = playbackRate;
    } else {
      // Set playbackRate when loaded
      audioRef.current.addEventListener('loadeddata', () => {
        if (audioRef.current) {
          audioRef.current.playbackRate = playbackRate;
        }
      }, { once: true });
    }
  }, [adState, playbackSpeed, playing?.id]);

  const activeAudio = adState.adMode
      ? ads[adState.adIndex % ads.length]?.audio
      : sources?.find(s => (s.sectionIndex ?? 0) === adState.sectionIndex);

  //useEffect(() => console.log({ adState, finalAdCount }), [adState, finalAdCount]);

  const audioElem = (
    <audio ref={audioRef}
        onError={handleError}
        onTimeUpdate={handleProgress}
        onEnded={handleEnded}
        src={activeAudio?.uri}
        data-duration={activeAudio?.duration}
        data-testid="audio"
        preload={(!!preload).toString()} />
  );

  const seekbar = (
    <Stack direction="column" sx={{ flexGrow: 1, display: preClick && 'none' }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <Typography variant="caption">{renderDuration(position || 0)}</Typography>
        <Slider
            name="seek"
            value={adState.adMode
                ? adTimes[adState.adIndex]
                : position}
            size="small"
            max={duration || 1}
            step={0.1}
            valueLabelDisplay={displayMode === 'minimal' ? 'off' : 'auto'}
            valueLabelFormat={renderDuration}
            color={seekbarColor}
            marks={adTimes.map(p => ({ value: p }))}
            data-testid="seekbar"
            aria-label="Audio playback position"
            aria-valuetext={`${renderDuration(position || 0)} of ${renderDuration(duration || 0)}`}
            sx={{
              '.MuiSlider-thumb': {
                transition: 'left 0.5s linear',
              },
              '.MuiSlider-track': {
                transition: 'width 0.5s linear',
              },
              '.MuiSlider-markLabel': {
                fontSize: 10,
              },
            }}
            onChange={(event) => handleSeek(event.target.value, event)} />
        <Typography variant="caption">-{renderDuration(Math.max(duration - position, 0) || 0)}</Typography>
      </Stack>
      {displayMode !== 'minimal' && <CenterBox>
        <Typography variant="caption" data-testid="timeLeft">
          {renderDuration((Math.max(duration - position, 0) / playbackSpeed) || 0)} left ({playbackSpeed}x)
        </Typography>
      </CenterBox>}
    </Stack>
  );

  const rewindButton = (
    <SimpleButton
        onClick={(event) => handleSeek(position - 10, event)}
        disabled={adState.adMode}
        aria-label="Rewind 10 seconds"
        title="Rewind 10 seconds"
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            handleSeek(position - 10, e);
          }
        }}>
      <RewindIcon sx={{ fontSize: 32 }} />
    </SimpleButton>
  );

  const forwardButton = (
    <SimpleButton
        onClick={(event) => handleSeek(position + 10, event)}
        disabled={adState.adMode}
        aria-label="Forward 10 seconds"
        title="Forward 10 seconds"
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            handleSeek(position + 10, e);
          }
        }}>
      <ForwardIcon sx={{ fontSize: 32 }} />
    </SimpleButton>
  );

  const playButtonSize = displayMode === 'minimal' ? 32 : 64;

  const playButton = (
    playing?.id === id
        ? <SimpleButton
            onClick={handlePause}
            data-testid="pause-button"
            aria-label="Pause"
            title="Pause"
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter') {
                e.preventDefault();
                handlePause();
              }
            }}>
            <PauseIcon sx={{ fontSize: playButtonSize }} />
          </SimpleButton>
        : <SimpleButton
            onClick={handlePlay}
            data-testid="play-button"
            aria-label="Play"
            title="Play"
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter') {
                e.preventDefault();
                handlePlay(e);
              }
            }}>
            <PlayIcon sx={{ fontSize: playButtonSize }} />
          </SimpleButton>
  );

  useEffect(() => {
    const missingUriSources = sources?.filter(source => !source.uri);
    const missingUriAds = ads?.filter(ad => !ad.audio?.uri);

    if (missingUriSources?.length > 0) {
      setMessage({ children: `Error: Missing URI in sources for contentId ${contentId}`, severity: 'error' });
    }

    if (missingUriAds?.length > 0) {
      setMessage({ children: `Error: Missing URI in ads for contentId ${contentId}`, severity: 'error' });
    }
  }, [sources, ads, setMessage, contentId]);

  if (displayMode === 'minimal') {
    return (
      <>
        {audioElem}
        <Stack direction="row"
            spacing={2}
            ref={ref}
            data-testid="audio-player"
            role="region"
            aria-label="Audio player controls"
            sx={{
              minWidth: 300,
              alignItems: 'center',
              py: 1,
              ...sx,
            }}>

          {playButton}
          {preClick && <Box>{preClickLabel}</Box>}
          {seekbar}
        </Stack>
      </>
    );
  }

  return (
    <>
      {audioElem}
      <Stack direction="column"
          spacing={2}
          ref={ref}
          sx={sx}
          data-testid="audio-player"
          role="region"
          aria-label="Audio player controls">

        {seekbar}

        <Stack direction="row"
            spacing={8}
            sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          {rewindButton}
          {playButton}
          {forwardButton}

        </Stack>
      </Stack>
    </>
  );
});

export default AudioPlayer;
