
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import Select from '@mui/material/Select';

export const CenterBox = ({ children, sx, ...props }) => {
  return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sx,
    }} {...props}>
    {children}
  </Box>;
};

export const SimpleButton = forwardRef(({ sx, color, component: Component = Button, ...props }, ref) => {
  const navigate = useNavigate();

  if (!props.onClick && props.href?.startsWith('#')) {
    props.onClick = (e) => {
      e.preventDefault();
      navigate(props.href.slice(1));
    };
  }

  return <Component sx={{
    color: color || 'inherit',
    padding: 0,
    ...sx,
  }} ref={ref} {...props} />;
});

export const VtLink = forwardRef(((props, ref) => {
  const navigate = useNavigate();

  if (!props.onClick && props.href?.startsWith('#')) {
    props = {
      ...props,
      onClick: (e) => {
        e.preventDefault();
        navigate(props.href.slice(1));
      },
    };
  }

  return <Link ref={ref} {...props}>
  </Link>;
}));

export const VtButton = forwardRef((props, ref) => {
  const navigate = useNavigate();

  if (!props.onClick && props.href?.startsWith('#')) {
    props.onClick = (e) => {
      e.preventDefault();
      navigate(props.href.slice(1));
    };
  }

  return <Button ref={ref} {...props}>
  </Button>;
});

export const ActionButton = forwardRef(({ sx, size, ...props }, ref) => (
  <Button sx={{
    height: `${size || 40}px`,
    borderRadius: `${(size || 40)/2}px`,
    fontWeight: 'normal',
    maxWidth: 'fit-content',
    ...sx,
  }} ref={ref} variant="contained" color="black" {...props} />
));

export const SmallChip = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Chip size="small"
        sx={{
          maxWidth: 'min-content',
          fontSize: 12,
          padding: 1,
          borderRadius: 8,
          ...sx
        }}
        ref={ref}
        {...props} />
  );
});

export const VtAccordion = ({ children, sx, ...props }) => {
  return <Accordion disableGutters={true}
      elevation={0}
      sx={{
        margin: 0,
        ...sx,
      }}
      {...props}>
    {children}
  </Accordion>;
};

export const VtAccordionSummary = ({ children, sx, ...props }) => {
  return <AccordionSummary expandIcon={<ArrowDropDownIcon />}
      sx={{
        flexDirection: 'row-reverse',
        '.MuiAccordionSummary-content': {
          margin: 0,
        },
        '&.MuiAccordionSummary-root': {
          minHeight: 36,
        },
        ...sx
      }}
      {...props}>
    {children}
  </AccordionSummary>;
};

export const DarkMenu = ({ children, sx, ...props }) => {
  return (
    <Menu
        sx={{
          '& .MuiMenu-list': {
            color: 'darkMenu.contrastText',
            backgroundColor: 'darkMenu.dark',
          },
          ...sx
        }}
        {...props}>
      {children}
    </Menu>
  );
};

// Covers its closest relatively-positioned ancestor in the hierarchy with a semi-transparent
// loading indicator
export const LoadingOverlay = ({ loading }) => {
  return (
      <Box sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: loading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'gray.main',
          opacity: 0.6,
          zIndex: 100,
      }}>
        <CircularProgress />
      </Box>
  );
};

// Just a box, in the style of an input control. Used to visually indicate data collection.
export const InputBox = ({ children, sx, ...props }) => {
  return (
    <Box sx={{
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: 2,
          overflow: 'visible',
          ...sx
        }}
        {...props}>
      {children}
    </Box>
  );
};

export const VtSelect = ({ size, children, label, helperText, FormControlProps, ...props }) => {
  return (
    <FormControl
        {...FormControlProps}
        sx={{
          '& label:not(.MuiInputLabel-shrink)': {
            transform: size === 'small' && 'translate(14px, 10px) scale(1)'
          },
          ...FormControlProps?.sx,
        }}>
      <InputLabel>{label}</InputLabel>
      <Select size={size} label={label} {...props}>
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

