import { forwardRef, useMemo, useState, useRef, useContext } from 'react';

import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SimpleButton, DarkMenu } from '../components.js';
import { PlaybackSpeedIcon } from '../icons.js';
import { Audio } from '../context.js';
import AudioPlayer from '../audio/AudioPlayer.js';

const MiniPlayer = forwardRef(({
      content,
      version,
      publicMode,
      ads,
      preload,
      sx,
    },
    ref) => {
  const { playbackSpeed, setPlaybackSpeed } = useContext(Audio);

  const versionData = useMemo(() => version || content.currentVersionData, [version, content]);

  const sources = useMemo(() =>
      versionData?.sections ? versionData.sections : [versionData?.audio],
      [versionData]);

  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const moreRef = useRef(null);
  const handleMoreMenuClose = (event) => {
    event.stopPropagation();
    setMoreMenuOpen(false);
  };

  //console.log({ content, version, ads, sources });

  return (
    <Stack direction="column">
      <Stack direction="row"
          spacing={1}
          sx={{ width: '100%', alignItems: 'center', ...sx }}>

        {versionData?.status === 'RECORDED'
            && <AudioPlayer sources={sources}
                  finalAdCount={versionData.finalAdCount}
                  publicMode={publicMode}
                  preload={preload}
                  ads={ads}
                  contentId={content.id}
                  organizationId={content.organization}
                  displayMode="minimal"
                  seekbarColor="black"
                  ref={ref} />}

        <SimpleButton ref={moreRef} onClick={() => setMoreMenuOpen(true)} sx={{ flexShrink: 0 }}>
          <MoreVertIcon fontSize="medium" />
          <DarkMenu anchorEl={moreRef.current}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              open={moreMenuOpen}
              onClose={handleMoreMenuClose}
              MenuListProps={{ dense: true }}>
            <MenuItem dense={true}>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <PlaybackSpeedIcon fontSize="small" />
                  <Box>Playback speed</Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
                  <SimpleButton onClick={() => setPlaybackSpeed(playbackSpeed - 0.25)}>
                    <ChevronLeftIcon fontSize="small" />
                  </SimpleButton>
                  <Box sx={{ minWidth: 24, textAlign: 'center' }}>{playbackSpeed}</Box>
                  <SimpleButton onClick={() => setPlaybackSpeed(playbackSpeed + 0.25)}>
                    <ChevronRightIcon fontSize="small" />
                  </SimpleButton>
                </Stack>
              </Stack>
            </MenuItem>
          </DarkMenu>
        </SimpleButton>
      </Stack>
      <Box sx={{
            alignSelf: 'flex-end',
            mt: -1,
            mr: 1,
          }}>
        <Typography
          variant="caption"
          sx={{
            fontWeight: 700,
            color: 'text.disabled',
          }}
        >
          Powered by&nbsp;
          <Link href="https://voicetopics.com"
              underline="none"
              sx={{
                color: 'purple.main',
              }} >
            VoiceTopics
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
});

export default MiniPlayer;
