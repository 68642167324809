import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';

import { fromVoiceId } from '../../src/voices.js';

// TODO: Refactor to not duplicate things in src/common.js
export function escapeRegex(s) {
  if (!s) throw Error(`Empty input: ${s}`);
  return s.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const PROD_HOSTS = ['voicetopics.com', 'www.voicetopics.com'];
export const DEV_HOSTS = ['dev.voicetopics.com'];

export function now() {
  return dayjs().toISOString();
}

export function* range(n, start = 0) {
  for (let i = start; i < n + start; ++i) yield i;
}

export function createId({ bytes, prefix } = {}) {
  bytes ||= 6;
  return (prefix ? prefix + '-' : '') +
      Array.from(range(bytes * 2))
          .map(i => Math.floor(Math.random() * 16).toString(16))
          .join('');
}

export function listenAllEvents(source, listener) {
  for (const key in source) {
    if (/^on/.test(key)) {
      source.addEventListener(key.substr(2), listener);
    }
  }
}

export async function createAudio(buffer) {
  const audioContext = new AudioContext();
  const audioBuffer = await audioContext.decodeAudioData(buffer);
  const audioSource = audioContext.createBufferSource();
  audioSource.buffer = audioBuffer;
  audioSource.connect(audioContext.destination);
  return audioSource;
}

export async function timeOperation(operation) {
  const startTime = performance.now();
  const result = await operation();
  return [performance.now() - startTime, result];
}

export function toTitleCase(str) {
  if (!str) return;

  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export async function fetchOrganizations(userInfo, impersonationUser) {
  if (!userInfo) return;

  const [loadTime, response] = await timeOperation(() => fetch('/api/me/organizations', {
    headers: impersonationUser ? { impersonate: impersonationUser } : {},
  }));

  if (response.ok) {
    const organizations = await response.json();

    mixpanel.track('Load organizations', { loadTime, length: organizations.length });
    return organizations;
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error loading organizations', { message: errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function completeNewUserChecklistItem(userInfo, setUserInfo, impersonationUser, flag, value) {
  if (!userInfo || userInfo.newUserFlags?.[flag]) return;

  const headers = { 'Content-Type': 'application/json' };
  if (impersonationUser) {
    headers.impersonate = impersonationUser;
  }
  const response = await fetch('/api/me/completeNewUserChecklistItem', {
    method: 'POST',
    headers,
    body: JSON.stringify({ flag, value }),
  });

  if (response.ok) {
    setUserInfo(await response.json());
  } else {
    throw Error(await response.text());
  }
}

export async function fetchOrganization(userInfo, impersonationUser, id) {
  if (!userInfo) return null;

  const headers = impersonationUser ? { impersonate: impersonationUser } : {};
  const [loadTime, response] = await timeOperation(() =>
      fetch(`/api/organization/${id}`, { headers }));

  if (response.ok) {
    mixpanel.track('Load organization', { loadTime });
    return await response.json();
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error loading organization', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function createOrganization(userInfo, impersonationUser) {
  const headers = { 'Content-Type': 'application/json' };
  if (impersonationUser) {
    headers.impersonate = impersonationUser;
  }
  const [loadTime, response] = await timeOperation(() => fetch('/api/organization', {
    method: 'PUT',
    headers,
    body: JSON.stringify({ name: `${userInfo.displayName || userInfo.email}'s Organization` }),
  }));

  if (response.ok) {
    mixpanel.track('Create organization', { loadTime });
    return await response.json();
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error creating organization', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function deleteOrganization(impersonationUser, organizationId, hardDelete) {
  const headers = { 'Content-Type': 'application/json' };
  if (impersonationUser) {
    headers.impersonate = impersonationUser;
  }
  const [loadTime, response] = await timeOperation(() =>
      fetch(`/api/organization/${organizationId}/delete`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ hardDelete }),
      }));

  if (response.ok) {
    mixpanel.track('Deleted organization', { loadTime });
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error deleting organization', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function updateMembership(impersonationUser, organizationId, status) {
  const headers = { 'Content-Type': 'application/json' };
  if (impersonationUser) {
    headers.impersonate = impersonationUser;
  }
  const [loadTime, response] = await timeOperation(() => fetch(`/api/organization/${organizationId}/member`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ status }),
  }));

  if (response.ok) {
    mixpanel.track('Updated organization invite', { loadTime });
    return await response.json();
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error updating organization invite', { loadTime, errorMessage });
    throw Error(errorMessage);
  }
};

export function updateOrganization({ organizations, setOrganizations, selectedOrganization, setSelectedOrganization }) {
  return (newOrganization) => {
    if (selectedOrganization.id === newOrganization.id) {
      setSelectedOrganization(newOrganization);
    }

    setOrganizations(organizations.map(organization => organization.id === newOrganization.id ? newOrganization : organization));
  };
}

export function blurOnEnter(event) {
  if ((event.key === 'Enter' && !event.shiftKey) || event.key === 'Escape') {
    event.preventDefault();
    event.stopPropagation();
    event.target.blur();
  }
}

export function actionOrCancel(action, cancel) {
  return (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      return action(event);
    } else if (event.key === 'Escape') {
      return cancel(event);
    }
  };
}

export function adminCheck(userInfo, organization) {
  const memberInfo = userInfo && organization &&
      organization.members.find(member => member.email === userInfo.email);
  return memberInfo && memberInfo.admin && memberInfo.status === 'ACCEPTED';
}

export async function fetchUserInfo(impersonationUser, setImpersonationUser) {
  const response = await fetch('/api/me', {
    headers: impersonationUser ? { impersonate: impersonationUser } : {},
  })

  if (response.ok) {
    return await response.json();
  } else if (response.status === 400 && impersonationUser) {
    setImpersonationUser?.(false);
    return null;
  } else {
    return { unauthenticated: true };
  }
}

export async function fetchContent(contentId, impersonationUser) {
  const [loadTime, response] =
      await timeOperation(() => fetch(`/api/content/${contentId}`, {
        headers: impersonationUser ? { impersonate: impersonationUser } : {}
      }));

  if (response.ok) {
    const contentItem = await response.json();
    //console.log({ contentItem });
    mixpanel.track('Load content', { loadTime });
    return contentItem;
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error loading content', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function fetchAd(adId, impersonationUser) {
  const [loadTime, response] =
      await timeOperation(() => fetch(`/api/ads/${adId}`, {
        headers: impersonationUser ? { impersonate: impersonationUser } : {}
      }));

  if (response.ok) {
    const ad = await response.json();
    //console.log({ ad });
    mixpanel.track('Load ad', { loadTime });
    return ad;
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error loading ad', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function fetchVersions(contentId, impersonationUser) {
  const [loadTime, response] =
      await timeOperation(() => fetch(`/api/content/${contentId}/versions`, {
        headers: impersonationUser ? { impersonate: impersonationUser } : {}
      }));

  if (response.ok) {
    const versions = await response.json();
    mixpanel.track('Load versions', { loadTime, count: versions.length });
    return versions;
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error loading versions', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function executeNotificationAction({
      notification,
      navigateRef,
      organizations,
      selectedOrganization,
      setSelectedOrganization,
    }) {
  // Change the selected organization if needed
  const newOrganizationId = notification.data?.oragnizationId;
  if (newOrganizationId && newOrganizationId !== selectedOrganization.id) {
    const newOrganization = organizations.find(o => o.id === notification.action?.organization);
    if (newOrganization) {
      setSelectedOrganization(newOrganization);
    }
  }

  if (notification.action?.path) {
    navigateRef.current(notification.action.path);
  }
}

export async function recordAudio({ voiceId, input, organization }) {
  const { voice, source } = fromVoiceId(voiceId);
  const [loadTime, response] = await timeOperation(() =>
      fetch(`/api/record`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ voice, input, organization, source }),
      }));
  if (response.ok) {
    mixpanel.track('Recorded audio', { loadTime });
    const audioBuffer = await response.arrayBuffer();
    return await createAudio(audioBuffer);
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error recording audio', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function fetchAudio(url) {
  const [loadTime, response] = await timeOperation(() => fetch(url, { headers: { Origin: '*' } }));
  if (response.ok) {
    mixpanel.track('Fetched audio', { loadTime });
    const audioBuffer = await response.arrayBuffer();
    return await createAudio(audioBuffer);
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error fetching audio', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export function renderDuration(duration) {
  if (!duration && duration !== 0) return '-:--';

  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 % 60)
  const seconds = Math.floor(duration % 60);
  if (hours) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
  }
}

export async function fetchUsageLimit(organization, impersonationUser) {
  const response = await fetch(`/api/organization/${organization.id}/usageLimit`,
      { headers: impersonationUser ? { impersonate: impersonationUser } : {} });
  if (response.ok) {
    return await response.json();
  } else {
    throw Error(await response.text());
  }
}

export async function fetchVoices({ organization, impersonationUser, allLanguages = false, allSources = false, ignoreBlacklist = false }) {
  if (!organization) throw Error('Missing organization');
  const queryParams = [];
  if (allLanguages) queryParams.push('allLanguages=true');
  if (allSources) queryParams.push('allSources=true');
  if (ignoreBlacklist) queryParams.push('ignoreBlacklist=true');
  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

  const [loadTime, response] = await timeOperation(() => fetch(
      `/api/organization/${organization.id}/voices${queryString}`,
      {
        headers: impersonationUser ? { impersonate: impersonationUser } : {},
      }));

  if (response.ok) {
    const voices = await response.json();
    mixpanel.track('Load voices', { loadTime, length: voices.length });
    return voices;
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error loading voices', { message: errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

export async function fetchLexicon({ organizationId, impersonationUser, params }) {
  if (!organizationId) throw Error('Missing organizationId');

  const headers = { 'Content-Type': 'application/json' };
  if (impersonationUser) {
    headers.impersonate = impersonationUser;
  }
  const response = await fetch(`/api/organization/${organizationId}/lexicon`, {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });
  if (response.ok) {
    return await response.json();
  } else {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
}

export async function markRead(notification, setMessage, impersonationUser) {
  const headers = { 'Content-Type': 'application/json' };
  if (impersonationUser) {
    headers.impersonate = impersonationUser;
  }

  const response =
      await fetch(`/api/notifications/${notification.eventTime}/read`, {
        method: 'POST',
        headers
      });
  if (!response.ok) {
    setMessage?.({ children: await response.text(), severity: 'error' });
  }
};

export function newlinesToBreaks(text) {
  const parts = text.split("\n");
  for (let index = parts.length - 1; index > 0; --index) {
    parts.splice(index, 0, <br key={`break-${index}`} />);
  }
  return parts;
}

export function urlWith(url, newProperties) {
  let newUrl = new URL(url);
  for (let property of ['protocol', 'password', 'username', 'host', 'pathname', 'search', 'hash']) {
    if (newProperties[property] || newProperties[property] === '') {
      newUrl[property] = newProperties[property];
    }
  }
  return newUrl;
}

export function paramsWith(searchParams, newParams) {
  let newSearchParams = new URLSearchParams(searchParams);
  for (let [key, value] of Object.entries(newParams)) {
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
  }
  return newSearchParams;
}

export async function logout() {
  await fetch('/logout');

  const newLocation = urlWith(
      window.location,
      {
        pathname: '/',
        hash: '/',
        search: '',
      });
  console.log(`Logout, navigate to ${newLocation.toString()}`);
  window.location.href = newLocation.toString();
}

export function isDifferent(newData, oldData) {
  let diff;
  if (Array.isArray(newData)) {
    // Arrays must match each element
    diff = newData.length !== oldData.length
        || newData.some((el, i) => isDifferent(el, oldData[i]));
  } else if (typeof newData !== 'object' || newData === null) {
    // For scalar values, check equality
    diff = newData !== oldData;
  } else {
    // For objects, recurse
    diff = Object.entries(newData).some(([key, value]) => isDifferent(value, oldData[key]));
  }

  //console.log({ newData, oldData, isDifferent: diff });

  return diff;
}

export function getChanges(newData, oldData) {
  const changes = {};
  for (let [key, value] of Object.entries(newData)) {
    if (typeof value !== 'object' || Array.isArray(value)) {
      // For arrays and scalars, use isDifferent to check equality
      if (isDifferent(value, oldData?.[key])) {
        changes[key] = value;
      }
    } else if (value === null) {
      if (oldData?.[key] !== null) {
        changes[key] = value;
      }
    } else {
      // For objects, recurse
      const childChanges = getChanges(value, oldData?.[key]);
      if (childChanges) {
        // TODO: Should this be = childChanges? What is the intended format here?
        changes[key] = value;
      }
    }
  }

  //console.log({ newData, oldData, changes });

  return Object.entries(changes).length > 0 ? changes : undefined;
}

export function isValidUrl(s) {
  try {
    new URL(s);
    return true;
  } catch(e) {
    return false;
  }
}

export function locationEquals(a, b) {
  return a.pathname === b.pathname && a.search === b.search && a.hash === b.hash;
}

export function truncateText(text, maxLength) {
  if (text?.length > maxLength) return text.substring(0, maxLength - 3) + '...';
  return text;
}

export function lexiconEq(a, b) {
  a ||= [];
  b ||= [];
  if (a.length !== b.length) return false;
  return a.every(aElem => b.some(bElem =>
      aElem.target === bElem.target
          && aElem.replacement=== bElem.replacement
          && aElem.caseSensitive === bElem.caseSensitive));
}

export async function requestReprocessing(contentId, options = {}) {
  const { impersonationUser } = options;

  const [loadTime, response] = await timeOperation(() =>
    fetch(`/api/content/${contentId}/retry`, {
      method: 'POST',
      headers: impersonationUser ? { impersonate: impersonationUser } : {}
    })
  );

  if (response.ok) {
    mixpanel.track('Requested reprocessing', { loadTime });
  } else {
    const errorMessage = await response.text();
    mixpanel.track('Error requesting reprocessing', { errorMessage, loadTime });
    throw Error(errorMessage);
  }
}

// Returns true iff `childId` is a descendent of `parentId` in the hierarchy described by `groups`
export function groupContains({ groups, childId, parentId }) {
  // Identity check
  if (childId === parentId) return true;

  // Abort early if we have no group definitions
  if (!groups) return false;

  // Recursive check up the tree
  let currentGroup = groups.find(g => g.id === childId);
  while (currentGroup?.parent) {
    if (currentGroup.parent === parentId) {
      return true;
    }
    currentGroup = groups.find(g => g.id === currentGroup.parent); /* eslint-disable-line no-loop-func */
  }

  // Otherwise return false
  return false;
}

